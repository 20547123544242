import { ArrowTopRightOnSquareIcon } from '@heroicons/react/16/solid';

// import srcGillAlexander from './images/gillalexander.png';
// import srcRobPizzola from './images/robpizzola.jpeg';
// import srcSpanky from './images/spanky.jpg';

type Testimonial = { text: string; source: string; href?: string; role?: string; imgPath?: string };

const TESTIMONIALS: Testimonial[] = [
  {
    text: `"When RAS bets, the world listens."`,
    source: 'Spanky',
    role: 'Professional bettor',
    href: 'https://twitter.com/spanky/status/1191810939852611585',
    // imgPath: srcSpanky,
  },
  {
    text: '"The most respected pick service in the world."',
    source: 'Rob Pizzola',
    role: 'Professional bettor',
    href: 'https://www.youtube.com/watch?v=mj40UN2Wa0M',
    // imgPath: srcRobPizzola,
  },
  {
    text: '"A pillar of the pick-selling industry."',
    source: 'Gill Alexander',
    href: 'https://raspicks.substack.com/p/transcript-ras-pro-bettor-joins-vsins',
    role: 'VSiN host',
    // imgPath: srcGillAlexander,
  },
];

export default function Testimonials() {
  return (
    <div className="mx-auto grid max-w-5xl grid-cols-1 space-y-4 md:grid-cols-3 md:space-x-8 md:space-y-0">
      {/* <div className="flex max-w-xl flex-col items-start justify-between"> */}
      {/* <div className="mt-8 flex flex-col justify-center space-y-4 px-4 sm:flex-row sm:space-x-8 sm:space-y-0 sm:px-0"> */}
      {TESTIMONIALS.map(({ text, source, role, href }) => (
        <div
          // className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80"
          className="relative isolate flex flex-col items-center justify-between space-y-4 overflow-hidden rounded border border-white/5 bg-white/5 p-4 text-center shadow-2xl backdrop-blur-sm"
          key={href}
        >
          <div className="flex flex-col items-center space-y-4">
            {/* <img src={imgPath} className="h-16 w-16 rounded-full bg-zinc-400" /> */}
            <blockquote className="m-0 inline-block text-xl leading-6 text-zinc-200">
              <span>
                {text}
                {/* <a href={href} className="align-bottom sm:hidden" target="_blank" rel="noreferrer">
                  <ArrowTopRightOnSquareIcon className="h-4 w-4 text-zinc-400  hover:text-white" />
                </a> */}
              </span>
            </blockquote>
          </div>
          <figcaption className="text-center">
            <div className="text-sm font-semibold text-white">{source}</div>
            <div className="text-sm text-zinc-400">{role}</div>
          </figcaption>
          <a
            href={href}
            target="_blank"
            rel="noreferrer"
            className="absolute bottom-0 right-0 pb-4 pr-4"
          >
            <ArrowTopRightOnSquareIcon className="h-4 w-4 text-zinc-400  hover:text-white" />
          </a>
        </div>
      ))}
    </div>
  );
}
