import srcLasVegasReviewJournal from '@/components/shared/las-vegas-review-journal.png';

import srcBeBetterBettors from './images/be-better-bettors.png';
import srcCirclesOff from './images/circles-off.png';
import srcEspn from './images/espn.png';
import srcGamingToday from './images/gaming-today.png';
import srcTheWashingtonPost from './images/the-washington-post.png';
import srcVsin from './images/vsin.png';
import srcYahoo from './images/yahoo.png';

type Logo = { src: string; alt: string };

const LOGOS: Logo[] = [
  {
    alt: 'Las Vegas Review-Journal',
    src: srcLasVegasReviewJournal,
  },
  {
    alt: 'The Washington Post',
    src: srcTheWashingtonPost,
  },
  {
    alt: 'ESPN',
    src: srcEspn,
  },
  {
    alt: 'Be Better Bettors',
    src: srcBeBetterBettors,
  },
  {
    alt: 'Circles Off',
    src: srcCirclesOff,
  },
  {
    alt: 'VSiN',
    src: srcVsin,
  },
  {
    alt: 'Gaming Today',
    src: srcGamingToday,
  },
  {
    alt: 'Yahoo',
    src: srcYahoo,
  },
];

export default function LogoCloud() {
  return (
    <div className="mt-24 space-y-6">
      <div className="mb-12 text-base font-semibold text-zinc-200">RAS has been featured in</div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <ul className="-mx-6 grid grid-cols-2 items-center sm:max-w-none sm:grid-cols-4 md:mx-0">
          {/* <div className="inline-flex w-full flex-nowrap gap-x-20 overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]"> */}
          {/* {Array(2)
            .fill(true)
            .map((_, index) => (
              <ul
                className="flex w-max animate-infinite-scroll items-center justify-center gap-x-20 md:justify-start"
                key={index}
              > */}
          {LOGOS.map(({ src, alt }) => (
            <li key={src} className="p-6">
              <img
                src={src}
                alt={alt}
                className="mx-auto h-full max-h-9 w-28 max-w-min object-contain lg:w-40"
              />
            </li>
          ))}
          {/* </ul>
            ))} */}
          {/* </div> */}
        </ul>
      </div>
    </div>
  );
}
