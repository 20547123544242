export default Page;
import './index.module.less';

import { ArrowUpRightIcon } from '@heroicons/react/20/solid';
import { CheckBadgeIcon } from '@heroicons/react/24/outline';

import AllTimeChart from './components/AllTimeChart';
import Footer from './components/Footer';
import Header from './components/Header';
import Hero from './components/Hero';
import LogoCloud from './components/LogoCloud';
import OddsScreen from './components/OddsScreen';
import ReleaseDemo from './components/ReleaseDemo';
import Testimonials from './components/Testimonials';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/16/solid';
import Podcasts from './components/Podcasts';

function Page() {
  return (
    <div className="shrink-0">
      <div className="sticky top-0 z-50 bg-emerald-500 p-2 text-center text-sm font-semibold text-white">
        NFL & college football services are now available.{' '}
        <a href="/order" className="text-white underline hover:text-emerald-100">
          Join now.
        </a>
      </div>
      <Header />
      <Hero />
      <div className="mt-12 space-y-8">
        <div className="mx-2 mt-px flex w-auto flex-col items-center justify-center space-y-12 rounded bg-gradient-to-b from-white/5 to-transparent p-6 text-center ring-1 ring-white/20 sm:mx-12 sm:p-8">
          <div className="space-y-4">
            <div className="text-base font-semibold text-emerald-500">Market respected</div>
            <h4 className="my-2 text-3xl font-semibold text-white md:text-4xl">
              Picks that disrupt the market and beat the close
            </h4>
            <p className="mx-auto max-w-prose leading-6 text-zinc-400">
              When RAS releases a play, sportsbooks will adjust their odds quickly. RAS beats the
              closing line more consistently and by bigger margins than anyone in the space. Even
              for the biggest games in the biggest markets -- like the 2023 Super Bowl.
            </p>
            <p className="mx-auto max-w-prose leading-6 text-zinc-400">
              <a
                className="inline-flex w-full items-center justify-center space-x-1 overflow-hidden rounded px-6 py-3 text-base font-medium text-zinc-200 ring-1 ring-inset ring-white/10 transition hover:bg-white/5 hover:text-white sm:w-auto"
                href="/order"
              >
                <span>Watch RAS move the Super Bowl total</span>
                <ArrowTopRightOnSquareIcon className="h-4 w-4 hover:text-white" />
              </a>
            </p>
          </div>
          <div className="-mx-2 mt-16 max-w-6xl space-y-4 sm:mx-auto sm:px-4 md:px-8 lg:grid lg:max-h-[14rem] lg:grid-cols-12 lg:items-center lg:space-x-8 lg:space-y-0">
            <ReleaseDemo className="relative col-span-4 col-start-1 row-start-1 text-left xl:col-span-5" />
            <OddsScreen className="relative col-span-8 col-start-5 row-start-1 xl:col-span-8 xl:col-start-6" />
          </div>
        </div>
        <div className="mx-2 grid grid-cols-6 gap-8 sm:mx-12">
          <div className="relative col-span-6 mt-px flex w-auto flex-col justify-center space-y-4 rounded bg-gradient-to-b from-white/5 to-transparent p-6 text-center ring-1 ring-white/20 sm:p-8 md:items-start lg:col-span-4">
            <span className="text-base font-semibold text-emerald-500">Long-term winner</span>
            <h4 className="my-2 text-3xl font-semibold text-white md:text-left md:text-4xl">
              25+ years of success for subscribers
            </h4>
            <p className="max-w-prose leading-6 text-zinc-400 md:pb-52 md:text-left">
              With win rates near 56 percent in the short, medium and long term, no one is a bigger
              favorite to win each year.
            </p>
            <AllTimeChart />
          </div>
          <div className="col-span-6 mt-px flex w-auto flex-col items-center justify-center space-y-4 rounded bg-gradient-to-b from-white/5 to-transparent p-6 text-center ring-1 ring-white/20 sm:p-8 lg:col-span-2">
            <div className="flex h-12 w-12 flex-col justify-center rounded-full bg-white/10">
              <CheckBadgeIcon className="m-auto h-8 w-8 text-white" />
            </div>
            <span className="text-base font-semibold text-emerald-500">Independently verified</span>
            <h4 className="my-2 text-3xl font-semibold text-white md:text-4xl">
              Plays tracked by a trusted 3rd party
            </h4>
            <p className="max-w-prose text-zinc-400">
              Since 2020, all of our plays have been independently verified by betstamp.
            </p>
            <a
              className="inline-flex items-center justify-center space-x-1 overflow-hidden rounded px-6 py-2 text-sm font-medium text-zinc-200 ring-1 ring-inset ring-white/10 transition hover:bg-white/5 hover:text-white"
              href="https://betstamp.app/u/RAS"
              target="_blank"
              rel="noreferrer"
            >
              <span>View betstamp profile</span>
              <ArrowUpRightIcon className="h-4 w-4" />
            </a>
          </div>
        </div>
        <div className="mx-2 mt-px flex w-auto flex-col items-center justify-center space-y-12 rounded bg-gradient-to-b from-white/5 to-transparent p-6 text-center ring-1 ring-white/20 sm:mx-12 sm:p-8">
          <div className="space-y-4">
            <div className="text-base font-semibold text-emerald-500">Industry acclaimed</div>
            <h4 className="my-2 text-3xl font-semibold text-white md:text-4xl">
              Game recognizes game
            </h4>
            <p className="mx-auto max-w-prose leading-6 text-zinc-400">
              Professional bettors and media experts have praised RAS for its established success.
            </p>
          </div>
          <Testimonials />
        </div>
      </div>
      <Podcasts />
      <div
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true"
      ></div>
      <div className="mx-auto max-w-6xl text-center">
        <LogoCloud />
      </div>
      <Footer />
    </div>
  );
}
