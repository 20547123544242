import { ArrowRightIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

import { Logo } from '@/components/Logo';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/16/solid';
import { useRef } from 'react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

interface Link {
  href: string;
  text: string;
  target?: '_blank' | '_self';
  type?: 'button';
  className?: string;
}

const LINKS: Link[] = [
  {
    href: '/about',
    text: 'About',
    target: '_self',
  },
  // {
  //   href: '/archive',
  //   text: 'Results',
  //   target: '_self',
  // },
  {
    href: '/cfb-results',
    text: 'CFB Results',
    target: '_self',
  },
  {
    href: '/nfl-results',
    text: 'NFL Results',
    target: '_self',
  },
  {
    href: 'https://www.bettingtalk.com',
    text: 'Betting Talk',
    target: '_blank',
  },
  {
    href: '/order',
    text: 'Order',
    target: '_self',
    className: 'md:border-r md:border-white/50 md:pr-8',
  },
  {
    href: '/signin',
    text: 'Sign in',
    target: '_self',
  },
  {
    href: '/register',
    text: 'Get free releases',
    target: '_self',
    type: 'button',
  },
];

export default function Header() {
  const ref = useRef<HTMLInputElement>(null);

  return (
    <header className="relative h-0">
      <article className="absolute inset-x-0 top-0 z-10 flex flex-col justify-center border-b border-white/10 bg-emerald-50/5 shadow-sm backdrop-blur-sm has-[:checked]:bg-emerald-950/90 sm:flex-row">
        <nav
          className="flex h-fit w-full max-w-5xl flex-row items-center justify-between px-4 py-2 has-[:checked]:flex-col has-[:checked]:items-start has-[:checked]:justify-start 2xl:px-0"
          aria-label="Global"
        >
          <input
            ref={ref}
            id="header-checkbox"
            type="checkbox"
            className="peer/header-checkbox absolute z-[-1] m-0 border-collapse cursor-pointer border-none border-transparent bg-transparent p-0 opacity-0 lg:hidden"
            defaultChecked={false}
          />
          <label
            htmlFor="header-checkbox"
            className="absolute right-4 top-6 text-lg text-zinc-400 hover:text-white peer-checked/header-checkbox:hidden peer-focus-within/nav:hidden lg:pointer-events-none lg:hidden lg:opacity-0"
          >
            <Bars3Icon className="size-6 shrink-0 text-white" aria-hidden="true" />
          </label>
          <label
            htmlFor="header-checkbox"
            className="absolute right-4 top-6 hidden text-lg text-zinc-400 hover:text-white peer-checked/header-checkbox:inline-block peer-focus-within/nav:inline-block lg:pointer-events-none lg:hidden lg:opacity-0"
          >
            <XMarkIcon className="size-6 shrink-0 text-white" aria-hidden="true" />
          </label>
          <ul className="flex items-center space-x-8 text-sm font-medium">
            <li>
              <a href="/">
                <span className="sr-only">RAS</span>
                <Logo className="h-12 w-auto select-none" />
              </a>
            </li>
          </ul>
          <ul className="hidden items-baseline justify-end space-x-8 peer-checked/header-checkbox:top-6 peer-checked/header-checkbox:flex peer-checked/header-checkbox:w-full peer-checked/header-checkbox:flex-col peer-checked:items-start peer-checked/header-checkbox:space-x-0 peer-checked/header-checkbox:space-y-8 peer-checked/header-checkbox:py-6 lg:flex">
            {LINKS.map(({ href, text, type, target, className }) => (
              <a href={href} key={text} target={target}>
                <li
                  className={clsx(
                    'flex flex-row items-center text-sm font-semibold text-white hover:text-zinc-200',
                    type === 'button' &&
                      'group inline-flex justify-center space-x-0.5 overflow-hidden rounded bg-white/10 p-2 px-6 text-sm font-medium text-white ring-1 ring-inset ring-white/20 transition hover:bg-white/20 hover:text-white hover:ring-white/30',
                    className,
                  )}
                >
                  <span>{text}</span>
                  {target === '_blank' && (
                    <ArrowTopRightOnSquareIcon className="ml-1 inline-block size-4" />
                  )}
                  {type === 'button' && (
                    <>
                      <ChevronRightIcon className="h-5 w-5 group-hover:hidden" />
                      <ArrowRightIcon className="hidden h-5 w-5 p-px transition-transform group-hover:block" />
                    </>
                  )}
                </li>
              </a>
            ))}
          </ul>
        </nav>
      </article>
    </header>
  );
}
