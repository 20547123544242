import { ArrowRightIcon } from '@heroicons/react/20/solid';

import srcHeroPattern from './images/hero-pattern.svg';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/16/solid';

export default function Hero() {
  const stats = [
    {
      title: 'NFL',
      data: [
        { label: '2023 sides & totals', value: '54-32-3', units: '+18.55', percentage: '62.79' },
        {
          label: 'All-time sides & totals',
          value: '208-142-7',
          units: '+51.95',
          percentage: '59.43',
        },
      ],
    },
    {
      title: 'College football',
      data: [
        {
          label: '2021-2023 FBS pregame sides & totals',
          value: '113-73',
          units: '+32.7',
          percentage: '60.75',
        },
        {
          label: '2021-2023 FCS sides & totals',
          value: '41-20',
          units: '+19.0',
          percentage: '67.21',
        },
      ],
    },
  ];

  return (
    <div className="relative isolate overflow-hidden">
      {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
      <div className="bg-repeat" style={{ backgroundImage: `url('${srcHeroPattern}')` }}>
        <div className="border-b border-zinc-600/80 bg-gradient-to-bl from-emerald-400/50 to-70% px-4 lg:px-16">
          <div className="mx-auto max-w-6xl pb-12 pt-32 sm:py-24">
            <div className="mt-6 flex flex-col justify-center gap-x-8 gap-y-20 lg:flex-row lg:px-8 xl:px-16">
              <div className="my-auto text-left">
                <h1 className="mx-auto max-w-[310px] text-center text-5xl font-bold tracking-normal text-white min-[470px]:max-w-2xl min-[470px]:text-7xl sm:leading-none sm:tracking-tight lg:pe-4 lg:text-left">
                  Be a favorite to win
                </h1>
                <p className="mx-auto my-auto mt-10 max-w-prose text-center text-base leading-6 text-white/70 sm:max-w-2xl sm:text-lg sm:leading-8 md:text-base lg:text-left">
                  <div className="max-w-xl">
                    RAS boasts a 25+ year history of success in college football, with a 2021-2023
                    record of 161-100 (61.7%), yielding +50.8 flat units. Since starting NFL picks
                    in 2017, RAS has achieved a 208-142-7 record (59.43%) on sides and totals. Our
                    results are independently tracked by{' '}
                    <a
                      href="https://betstamp.app/u/RAS"
                      target="_blank"
                      className="text-white/70 underline"
                    >
                      betstamp
                    </a>
                    .
                  </div>
                </p>
                <div className="mt-10 flex flex-col items-center justify-center space-y-4 sm:flex-row sm:space-x-6 sm:space-y-0 lg:justify-start">
                  <a
                    className="inline-flex w-full justify-center space-x-0.5 overflow-hidden rounded bg-emerald-400/10 px-6 py-3 text-base font-medium text-emerald-400 ring-1 ring-inset ring-emerald-400 delay-150 ease-in-out hover:bg-emerald-400/10 hover:text-emerald-300 hover:ring-emerald-300 sm:w-auto"
                    href="/register"
                  >
                    Get free releases
                  </a>
                  <a
                    className="inline-flex w-full items-center justify-center space-x-1 overflow-hidden rounded px-6 py-3 text-base font-medium text-zinc-200 ring-1 ring-inset ring-white/10 transition hover:bg-white/5 hover:text-white sm:w-auto"
                    href="/order"
                  >
                    <span>Subscribe now</span>
                    <ArrowRightIcon className="h-4 w-4" />
                  </a>
                </div>
              </div>
              <div className="flex flex-col space-y-8 rounded bg-gradient-to-b from-zinc-950/30 to-zinc-950/50 p-6 ring-1 ring-white/20 lg:flex lg:flex-auto lg:justify-end">
                {stats.map(({ title, data }) => (
                  <div className="-m-2 flex flex-col rounded bg-white/5 p-4">
                    <span className="mb-2 text-center text-xs font-semibold lg:text-left">
                      {title}
                    </span>
                    <dl className="sm:grid-cols-auto grid w-auto grid-flow-row grid-cols-1 gap-0 space-y-8 sm:gap-8 sm:space-y-0 lg:grid-cols-1 lg:gap-0 lg:space-y-8">
                      {data.map((data) => (
                        <div key={data.label} className="flex flex-col text-center lg:text-left">
                          <dt className="text-sm font-normal text-zinc-200">{data.label}</dt>
                          <dd className="mt-2 text-xl font-semibold tracking-tight text-white">
                            {data.value}{' '}
                            <span className="text-sm text-zinc-400">({data.percentage}%)</span>
                          </dd>
                          <dd className="mx-auto mt-1 w-fit rounded bg-emerald-400/30 px-2.5 py-0.5 text-xs font-semibold text-white lg:mx-0">
                            {data.units} units
                          </dd>
                        </div>
                      ))}
                    </dl>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
