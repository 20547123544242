import { AreaChart } from '@tremor/react';
import { useState } from 'react';
import { InView } from 'react-intersection-observer';

const data = [
  { season: 1997, Units: 6.2 },
  { season: 1998, Units: 8 },
  { season: 1999, Units: 19.9 },
  { season: 2000, Units: 23.6 },
  { season: 2001, Units: 40.8 },
  { season: 2002, Units: 43.6 },
  { season: 2003, Units: 48.55 },
  { season: 2004, Units: 50.55 },
  { season: 2005, Units: 63.05 },
  { season: 2006, Units: 83 },
  { season: 2007, Units: 104 },
  { season: 2008, Units: 149.85 },
  { season: 2009, Units: 233.05 },
  { season: 2010, Units: 235 },
  { season: 2011, Units: 284.35 },
  { season: 2012, Units: 327.5 },
  { season: 2013, Units: 343.05 },
  { season: 2014, Units: 350.5 },
  { season: 2015, Units: 385.75 },
  { season: 2016, Units: 408.9 },
  { season: 2017, Units: 448.25 },
  { season: 2018, Units: 480.15 },
  { season: 2019, Units: 479.85 },
  { season: 2020, Units: 485 },
  { season: 2021, Units: 541.25 },
  { season: 2022, Units: 577 },
  { season: 2023, Units: 587.75 },
];

const dataFormatter = (number: number) => {
  return '+' + Intl.NumberFormat('us').format(number).toString();
};

const AllTimeChart = () => {
  const [inView, setInView] = useState(true);

  return (
    <>
      {/* <p className="h-4 text-xl text-white">inView: {inView}</p>
      <p className="h-4 text-xl text-white">{inView}</p> */}
      <InView onChange={setInView}>
        {({ ref }) => (
          <>
            <p className="text-lg text-white">{inView}</p>
            <AreaChart
              ref={ref}
              className="-z-10 h-44 md:absolute md:left-0 md:mx-auto md:h-80 md:px-6"
              data={data}
              index="season"
              categories={['Units']}
              colors={['emerald']}
              valueFormatter={dataFormatter}
              showLegend={false}
              showGridLines={false}
              showYAxis={false}
            />
          </>
        )}
      </InView>
    </>
  );
};

export default AllTimeChart;
