import clsx from 'clsx';

import Message from './Message';

const messages = [
  "We'll get started shortly...",
  'Set up 101 Kansas City/Philadelphia Over 50.5',
  'GO',
];

function ReleaseDemo({ className }: { className: string }) {
  return (
    <div
      className={clsx(
        'pointer-events-none relative z-20 flex h-full min-h-[14rem] overflow-hidden rounded bg-white/5 shadow-xl ring-1 ring-white/20 dark:bg-zinc-900 dark:ring-1 dark:ring-inset dark:ring-white/10 dark:backdrop-blur sm:max-h-[none] lg:min-w-[17rem]',
        className,
      )}
    >
      <div className="relative flex w-full flex-col">
        <div className="flex-none border-b border-zinc-500/30">
          <div className="flex h-8 items-center space-x-1.5 px-3">
            <div className="h-2.5 w-2.5 rounded-full bg-white/20" />
            <div className="h-2.5 w-2.5 rounded-full bg-white/20" />
            <div className="h-2.5 w-2.5 rounded-full bg-white/20" />
          </div>
        </div>
        <div className="flex flex-1 grow flex-col">
          <div className="relative flex min-h-0 flex-auto grow flex-col-reverse">
            <div className="flex flex-col py-4">
              {messages.map((message, index) => {
                return (
                  <div
                    key={index}
                    className={clsx(
                      index === 1 && 'max-h-0 animate-reveal opacity-0 animation-delay-2000',
                      index === 2 && 'max-h-0 animate-reveal opacity-0 animation-delay-4000',
                    )}
                  >
                    <Message index={index} message={message} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseDemo;
