import { twMerge } from 'tailwind-merge';

import { Logo } from '@/components/Logo';

const Message = ({
  index,
  message,
  className: classes,
}: {
  index: number;
  message: string;
  className?: string;
}) => {
  const userInfo = index === 0;

  const boldMessages = ['GO', 'CANCEL', 'OPPOSITE'];

  const messageContent = boldMessages.some((boldMessage) =>
    message.trim().startsWith(boldMessage),
  ) ? (
    <span className="text-base font-bold">{message}</span>
  ) : (
    <>{message}</>
  );

  return (
    <article className={twMerge('flex w-full justify-start py-2 pr-4 align-top', classes)}>
      <div className="mx-2 flex w-14 shrink-0 flex-col items-center justify-start pt-1">
        {userInfo && <Logo className="h-8 w-8 select-none rounded" />}
      </div>
      <div className="w-full">
        {userInfo && (
          <div>
            <span className="text-sm font-bold text-zinc-200">RAS</span>
          </div>
        )}
        <div className="space-y-3 text-[15px] leading-[1.375rem] text-zinc-300 sm:text-sm">
          <p className="my-0 whitespace-pre-wrap break-words">{messageContent || ''}</p>
        </div>
      </div>
    </article>
  );
};

export default Message;
