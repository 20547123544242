import clsx from 'clsx';
import type { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/UI/table';

const games = [
  {
    visitor: 'St. Bonaventure',
    home: 'Va Commonwealth',
    crisTop: '133',
    crisBottom: '11½-05',
    bOnlineTop: '133½',
    bOnlineBottom: '11½',
    circaLvTop: '132½',
    circaLvBottom: '11',
    pinnyTop: '133½u-11',
    pinnyBottom: '11½-07',
    pphTop: '133',
    pphBottom: '11½',
    fanDTop: '132½',
    fanDBottom: '11½-02',
    dKingTop: '133o-15',
    dKingBottom: '11½',
  },
  {
    visitor: 'UTEP',
    home: 'North Texas',
    pinnyTop: '112½o-12',
    pinnyBottom: '7½-07',
    crisTop: '113½u-11',
    crisBottom: '7-15',
    bOnlineTop: '112½o-15',
    bOnlineBottom: '7½',
    circaLvTop: '113',
    circaLvBottom: '7',
    pphTop: '112½',
    pphBottom: '7½',
    fanDTop: '112½-12',
    fanDBottom: '7½',
    dKingTop: '114',
    dKingBottom: '7½',
  },
  {
    isAnimated: true,
    rotation: 101,
    visitor: 'Kansas City Chiefs',
    visitorShort: 'KC',
    home: 'Philadelphia Eagles(*N*)',
    homeShort: 'PHI(*N*)',
    crisTop: '50½',
    crisTop1: '50½o-11',
    crisTop2: '50½o-15',
    crisTop3: '50½o-16',
    // crisTop4: '143u-41',
    // crisTop5: '143u-57',
    // crisTop6: '141½u-57',
    // crisTop7: '141½',
    // crisTop8: '141',
    // crisTop9: '141u-21',
    // crisTop10: '140',
    // crisTop11: '138½u-31',
    // crisTop12: '138½',
    crisBottom: '1½-12',
    bOnlineTop: '50½',
    bOnlineTop1: '51½',
    // bOnlineTop2: '140½u-15',
    // bOnlineTop3: '140u-20',
    bOnlineBottom: '1½-12',
    circaLvTop: '50½',
    circaLvTop1: '51',
    // circaLvTop2: '140½',
    // circaLvTop3: '139',
    circaLvBottom: '-1½',
    pinnyTop: '50½u-10',
    pinnyTop2: '50½u-08',
    pinnyTop3: '50½u-06',
    pinnyTop4: '50½u-10',
    pinnyBottom: '1',
    pphTop: '50½',
    pphTop1: '51',
    pphBottom: '1½',
    fanDTop: '50½u-12',
    fanDTop1: '50½o-14',
    fanDTop2: '50½u-15',
    fanDBottom: '1½',
    dKingTop: '50½',
    // dKingTop1: '140½',
    // dKingTop2: '140½o-15',
    // dKingTop3: '140',
    // dKingTop4: '139½',
    dKingBottom: '1½',
  },
  {
    visitor: 'South Carolina',
    home: 'Georgia',
    crisTop: '135½o-16',
    crisBottom: '10½-05',
    bOnlineTop: '136',
    bOnlineBottom: '10',
    circaLvTop: '136½',
    circaLvBottom: '10',
    pinnyTop: '136o-12',
    pinnyBottom: '10-11',
    pphTop: '136½',
    pphBottom: '10½',
    fanDTop: '136½',
    fanDBottom: '9½-18',
    dKingTop: '136½',
    dKingBottom: '10½-05',
  },
  {
    visitor: 'Western Michigan',
    home: 'Central Michigan',
    crisTop: '1',
    crisBottom: '141o-16',
    bOnlineTop: 'pk-15',
    bOnlineBottom: '140',
    circaLvTop: '1',
    circaLvBottom: '140½',
    pinnyTop: '1-08',
    pinnyBottom: '140½',
    pphTop: '1',
    pphBottom: '140½',
    fanDTop: '1½-02',
    fanDBottom: '140½-12',
    dKingTop: '1',
    dKingBottom: '140½u-15',
  },
  // {
  //   visitor: 'Gonzaga',
  //   home: 'Portland',
  //   crisTop: '13-05',
  //   crisBottom: '161½',
  //   bOnlineTop: '12½',
  //   bOnlineBottom: '162½',
  //   circaLvTop: '12½',
  //   circaLvBottom: '163½',
  //   pinnyTop: '12½-11',
  //   pinnyBottom: '163',
  //   pphTop: '13',
  //   pphBottom: '163½',
  //   fanDTop: '12½',
  //   fanDBottom: '163½',
  //   dKingTop: '13-05',
  //   dKingBottom: '163u-15',
  // },
];

const AnimatedRow = ({
  children,
  revealDelay,
  hideDelay,
}: {
  children: ReactNode;
  revealDelay?: string;
  hideDelay?: string;
}) => {
  return (
    <span className={clsx('w-auto', hideDelay && `animate-hide ${hideDelay}`)}>
      <span
        className={clsx(
          'w-auto',
          revealDelay && `absolute left-0 w-full animate-reveal px-px opacity-0 ${revealDelay}`,
        )}
      >
        {children}
      </span>
    </span>
  );
};

function OddsScreen({ className }: { className?: string }) {
  return (
    <div
      className={clsx(
        'relative flex max-h-[60vh] min-h-[14rem] overflow-hidden rounded bg-white/5 shadow-xl ring-1 ring-white/20 dark:ring-1 dark:ring-inset dark:ring-white/10 dark:backdrop-blur sm:max-h-[none] lg:max-w-full xl:min-w-[40rem]',
        className,
      )}
    >
      {/* <div className="absolute z-10 h-full w-full scale-y-150 animate-show bg-gradient-to-b from-zinc-900/70 from-30% via-transparent to-zinc-900/70 to-80% opacity-0 animation-delay-2000" /> */}
      <div className="relative flex w-full flex-col">
        <div className="flex-none border-b border-zinc-500/30">
          <div className="flex h-8 items-center space-x-1.5 px-3">
            <div className="h-2.5 w-2.5 rounded-full bg-white/20" />
            <div className="h-2.5 w-2.5 rounded-full bg-white/20" />
            <div className="h-2.5 w-2.5 rounded-full bg-white/20" />
          </div>
        </div>
        <div className="relative flex min-h-0 flex-auto flex-col">
          <Table className="pointer-events-none">
            <TableHeader>
              <TableRow className="border-b border-zinc-500/30 text-xs">
                <TableHead className="h-2 bg-white/5 px-px text-center font-black">Gm#</TableHead>
                <TableHead className="h-2 w-max bg-white/5 px-px font-black">Team</TableHead>
                <TableHead className="h-2 w-16 bg-white/5 px-px text-right font-black">
                  Cris
                </TableHead>
                <TableHead className="hidden h-2 w-16 bg-white/5 px-px text-right font-black sm:table-cell">
                  BOnline
                </TableHead>
                <TableHead className="h-2 w-16 bg-white/5 px-px text-right font-black">
                  CircLV
                </TableHead>
                <TableHead className="hidden h-2 w-16 bg-white/5 px-px text-right font-black md:table-cell">
                  Pinny
                </TableHead>
                <TableHead className="hidden h-2 w-16 bg-white/5 px-px text-right font-black md:table-cell">
                  PPH
                </TableHead>
                <TableHead className="h-2 w-16 bg-white/5 px-px text-right font-black">
                  FanD
                </TableHead>
                <TableHead className="hidden h-2 w-16 bg-white/5 px-px text-right font-black xl:table-cell">
                  DKing
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody className="text-xs text-zinc-200">
              {games.map((game, index) => (
                <TableRow
                  key={index}
                  className={twMerge(
                    'border-zinc-500/30 text-white/20 even:bg-zinc-400/5 hover:bg-inherit hover:even:bg-inherit',
                    game.isAnimated && 'text-white',
                  )}
                >
                  <TableCell className="border border-solid border-zinc-500/20 !p-px !px-0 text-center sm:!p-0">
                    {game.rotation ? (
                      <>
                        <div>{game.rotation}</div>
                        <div>{game.rotation + 1}</div>
                      </>
                    ) : (
                      <>
                        <div className="m-px mx-auto mb-1 h-3 w-6 rounded bg-zinc-700" />
                        <div className="m-px mx-auto mt-1 h-3 w-6 rounded bg-zinc-700" />
                      </>
                    )}
                  </TableCell>
                  <TableCell className="border border-solid border-zinc-500/20 !p-px !px-0 text-left sm:!p-0">
                    {game.rotation ? (
                      <>
                        <div>
                          <span className="sm:hidden md:table-cell xl:hidden">
                            {game.visitorShort}
                          </span>
                          <span className="hidden sm:table-cell md:hidden xl:table-cell">
                            {game.visitor}
                          </span>
                        </div>
                        <div>
                          <span className="sm:hidden md:table-cell xl:hidden">
                            {game.homeShort}
                          </span>
                          <span className="hidden sm:table-cell md:hidden xl:table-cell">
                            {game.home}
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="m-px mb-1 mr-auto h-3 w-14 rounded bg-zinc-700" />
                        <div className="m-px mr-auto mt-1 h-3 w-20 rounded bg-zinc-700" />
                      </>
                    )}
                  </TableCell>
                  <TableCell className="border border-solid border-zinc-500/20 !p-px !px-0 text-right sm:!p-0">
                    {game.rotation ? (
                      <>
                        <div
                          className={clsx(
                            'relative w-full p-px',
                            game.isAnimated && 'animate-highlight animation-delay-6000',
                          )}
                        >
                          <span
                            className={clsx(game.isAnimated && 'animate-hide animation-delay-6000')}
                          >
                            {game.crisTop}
                          </span>
                          <AnimatedRow
                            revealDelay="animation-delay-6000"
                            hideDelay="animation-delay-8000"
                          >
                            {game.crisTop1}
                          </AnimatedRow>
                          <AnimatedRow
                            revealDelay="animation-delay-8000"
                            hideDelay="animation-delay-9000"
                          >
                            {game.crisTop2}
                          </AnimatedRow>
                          <AnimatedRow revealDelay="animation-delay-9000">
                            {game.crisTop3}
                          </AnimatedRow>
                        </div>
                        <div>{game.crisBottom}</div>
                      </>
                    ) : (
                      <>
                        <div className="m-px mb-1 ml-auto h-3 w-10 rounded bg-zinc-700" />
                        <div className="m-px ml-auto mt-1 h-3 w-6 rounded bg-zinc-700" />
                      </>
                    )}
                  </TableCell>
                  <TableCell className="hidden border border-solid border-zinc-500/20 !p-px !px-0 text-right sm:table-cell sm:!p-0">
                    {game.rotation ? (
                      <>
                        <div
                          className={clsx(
                            'relative w-full p-px',
                            game.isAnimated && 'animate-highlight animation-delay-8000',
                          )}
                        >
                          <span
                            className={clsx(game.isAnimated && 'animate-hide animation-delay-8000')}
                          >
                            {game.bOnlineTop}
                          </span>
                          <AnimatedRow revealDelay="animation-delay-8000">
                            {game.bOnlineTop1}
                          </AnimatedRow>
                        </div>
                        <div>{game.bOnlineBottom}</div>
                      </>
                    ) : (
                      <>
                        <div className="m-px mb-1 ml-auto h-3 w-10 rounded bg-zinc-700" />
                        <div className="m-px ml-auto mt-1 h-3 w-6 rounded bg-zinc-700" />
                      </>
                    )}
                  </TableCell>
                  <TableCell className="border border-solid border-zinc-500/20 !p-px !px-0 text-right sm:!p-0">
                    {game.rotation ? (
                      <>
                        <div
                          className={clsx(
                            'relative w-full p-px',
                            game.isAnimated && 'animate-highlight animation-delay-6500',
                          )}
                        >
                          <AnimatedRow hideDelay={clsx(game.isAnimated && 'animation-delay-6500')}>
                            {game.circaLvTop}
                          </AnimatedRow>
                          <AnimatedRow revealDelay="animation-delay-6500">
                            {game.circaLvTop1}
                          </AnimatedRow>
                        </div>
                        <div>{game.circaLvBottom}</div>
                      </>
                    ) : (
                      <>
                        <div className="m-px mb-1 ml-auto h-3 w-10 rounded bg-zinc-700" />
                        <div className="m-px ml-auto mt-1 h-3 w-6 rounded bg-zinc-700" />
                      </>
                    )}
                  </TableCell>
                  <TableCell className="hidden border border-solid border-zinc-500/20 !p-px !px-0 text-right sm:!p-0 md:table-cell">
                    {game.rotation ? (
                      <>
                        <div
                          className={clsx(
                            'relative w-full p-px',
                            game.isAnimated && 'animate-highlight animation-delay-11000',
                          )}
                        >
                          <AnimatedRow hideDelay={clsx(game.isAnimated && 'animation-delay-11000')}>
                            {game.pinnyTop}
                          </AnimatedRow>
                          <AnimatedRow
                            revealDelay="animation-delay-11000"
                            hideDelay="animation-delay-12000"
                          >
                            {game.pinnyTop2}
                          </AnimatedRow>
                          <AnimatedRow
                            revealDelay="animation-delay-12000"
                            hideDelay="animation-delay-13000"
                          >
                            {game.pinnyTop3}
                          </AnimatedRow>
                          <AnimatedRow revealDelay="animation-delay-13000">
                            {game.pinnyTop4}
                          </AnimatedRow>
                        </div>
                        <div>{game.pinnyBottom}</div>
                      </>
                    ) : (
                      <>
                        <div className="m-px mb-1 ml-auto h-3 w-10 rounded bg-zinc-700" />
                        <div className="m-px ml-auto mt-1 h-3 w-6 rounded bg-zinc-700" />
                      </>
                    )}
                  </TableCell>
                  <TableCell className="hidden border border-solid border-zinc-500/20 !p-px !px-0 text-right sm:!p-0 md:table-cell">
                    {game.rotation ? (
                      <>
                        <div
                          className={clsx(
                            'relative w-full p-px',
                            game.isAnimated && 'animate-highlight animation-delay-9500',
                          )}
                        >
                          <AnimatedRow hideDelay={clsx(game.isAnimated && 'animation-delay-9500')}>
                            {game.pphTop}
                          </AnimatedRow>
                          <AnimatedRow revealDelay="animation-delay-9500">
                            {game.pphTop1}
                          </AnimatedRow>
                        </div>
                        <div>{game.pphBottom}</div>
                      </>
                    ) : (
                      <>
                        <div className="m-px mb-1 ml-auto h-3 w-10 rounded bg-zinc-700" />
                        <div className="m-px ml-auto mt-1 h-3 w-6 rounded bg-zinc-700" />
                      </>
                    )}
                  </TableCell>
                  <TableCell className="border border-solid border-zinc-500/20 !p-px !px-0 text-right sm:table-cell sm:!p-0">
                    {game.rotation ? (
                      <>
                        <div
                          className={clsx(
                            'relative w-full p-px',
                            game.isAnimated && 'animate-highlight animation-delay-10500',
                          )}
                        >
                          <AnimatedRow hideDelay={clsx(game.isAnimated && 'animation-delay-10500')}>
                            {game.fanDTop}
                          </AnimatedRow>
                          <AnimatedRow
                            revealDelay="animation-delay-10500"
                            hideDelay="animation-delay-14000"
                          >
                            {game.fanDTop1}
                          </AnimatedRow>
                          <AnimatedRow revealDelay="animation-delay-14000">
                            {game.fanDTop2}
                          </AnimatedRow>
                        </div>
                        <div>{game.fanDBottom}</div>
                      </>
                    ) : (
                      <>
                        <div className="m-px mb-1 ml-auto h-3 w-10 rounded bg-zinc-700" />
                        <div className="m-px ml-auto mt-1 h-3 w-6 rounded bg-zinc-700" />
                      </>
                    )}
                  </TableCell>
                  <TableCell className="hidden border border-solid border-zinc-500/20 !p-px !px-0 text-right sm:!p-0 xl:table-cell">
                    {game.rotation ? (
                      <>
                        <div className="relative w-full p-px">{game.dKingTop}</div>
                        <div>{game.dKingBottom}</div>
                      </>
                    ) : (
                      <>
                        <div className="m-px mb-1 ml-auto h-3 w-10 rounded bg-zinc-700" />
                        <div className="m-px ml-auto mt-1 h-3 w-6 rounded bg-zinc-700" />
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default OddsScreen;
